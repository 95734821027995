import { createGlobalStyle, styled } from "styled-components";
import { mediaQuery } from "./Mixins";
import InterTightRegular from "./fonts/InterTight-Regular.ttf";
import ManropeMedium from "./fonts/Manrope-Medium.ttf";
import PlayfairDisplayItalic from "./fonts/PlayfairDisplay-Italic.ttf";

export const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Manrope';
    src: url('${ManropeMedium}') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

*{
	padding: 0;
	margin: 0;
	border: 0;
}

*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.loader {
    position: absolute;

    display: inline-block;

    border: 0.35rem solid var(--white);
    border-top: 0.35rem solid transparent;
    border-radius: 50%;

    width: 2rem;
    height: 2rem;

    animation: loading 1s linear 0s infinite normal forwards;
  }

:focus,:active{outline: none;}
a:focus,a:active{outline: none;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

input[type=number], input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"] {
	-webkit-appearance: none;
	appearance: none;
}

nav,footer,header,aside{display: block;}

html,body{
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 16px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

a,button{touch-action: manipulation;}
input,button,textarea{font-family:inherit;border-radius: 0;}

input::-ms-clear{display: none;}
button{cursor: pointer;}
address {font-style: normal;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}
fieldset{padding: 0;}

h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight: 400; margin: 0;}



:root {
	--title-font: 'Playfair Display', sans-serif;
	--main-font: 'Inter Tight', sans-serif;
	--font-2: 'Manrope', sans-serif;
	
	--accent: #0158C3;
	--accent-hover: #0061d7;
	--accent-active: #004294;

	--white: #FFF;
	--black: #000;

	--container-padding: 15px;
}

html {
	font-size: 16px;
	min-height: 100%;
	min-width: 320px;

	/* scrollbar-color: #333 var(--primary);
	background: var(--primary); */
}

body {

	min-height: 100%;
	min-width: 320px;

	font-family: var(--main-font);
	font-weight: 400;
	line-height: 150%;

	display: flex;
	flex-direction: column;

	/* background: var(--primary);

	&::-webkit-scrollbar {
		background: var(--primary);
	}

	&::-webkit-scrollbar-track {
		background: var(--primary);
	}

	&::-webkit-scrollbar-thumb {
		background: #333;
		border: 4px solid var(--primary);
		border-radius: 8px;
	} */

	&.is-mobile-menu-active {
		overflow: hidden;
	}
	
}

* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}

.image-aspect-ratio {
	width: 100%; height: auto;
	@supports (aspect-ratio: 1) {
		aspect-ratio: var(--aspect-ratio);
	}
}

section {
	position: relative;
	z-index: 2;
}

.wrapper {
	position: relative;

	height: 100%;

	display: flex;
	flex-direction: column;

	flex: 1 1 auto;

	padding: 0.5rem;

	${mediaQuery._992(`
		padding: 3.125rem;
	`)}
}

.wrapper-background {
	position: absolute;
	left: 50%; top: 50%;
	transform: translate(-50%,-50%);

	border-radius: 1rem;
	background: center / cover no-repeat;

	width: calc(100% - 0.5rem * 2); height: calc(100% - 0.5rem * 2);

	${mediaQuery._992(`
		width: calc(100% - 3.125rem * 2); height: calc(100% - 3.125rem * 2);
	`)}
}

.main {
	position: relative;
	
	display: flex;
	flex-direction: column;
	justify-content: center;

	flex: 1 0 auto;

	overflow: hidden;
	padding: 1rem;
}

$container: 1440;

.container {
	padding-left: var(--container-padding);
	padding-right: var(--container-padding);
	
	@media (min-width: #{$container}px) {
		padding-left: max(var(--container-padding), calc(50% - #{math.div($container, 2)}px));
		padding-right: max(var(--container-padding), calc(50% - #{math.div($container, 2)}px));
	}
}

.visually-hidden {
	position: absolute;
	width: 1px; height: 1px;
	margin: -1px; padding: 0;
	border: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.visible-on-mob {
	${mediaQuery._992(`
		display: none !important;
	`)}
}

.visible-on-desktop {
	@media (max-width: 991px) {
		display: none !important;
	}
}

/* [aria-hidden="true"] {
	pointer-events: none; user-select: none;
} */

	


.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255, .8);
	backdrop-filter: blur(18px);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;

	transition: all .3s ease;
}
.preloader.hidden {
	opacity: 0;
	visibility: hidden;
}
	
	

    
`;

import { gql } from "@apollo/client";
import { client } from "../../..";

export const getLogo = async () => {
  return await client
    .query({
      query: gql`
        query getLogo {
            logo
        }
      `,
    })
};

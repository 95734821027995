import { createSlice } from "@reduxjs/toolkit";

interface IReviewFormStatus {
  lang: string;
}

const initialState: IReviewFormStatus = {
  lang: ""
};

export const langSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setLang } = langSlice.actions;
export default langSlice.reducer

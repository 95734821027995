import React from "react";
import sprites from './../../../assets/sprites.svg'

interface IRatingItemProps {
    id: string
    value: string
    name: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const RatingItem: React.FC<IRatingItemProps> = ({...props}) => {
  return (
    <>
      <input type="radio" {...props} required />
      <label htmlFor={props.id}>
        <svg width="24" height="24">
          <use href={sprites + "#star"}></use>
        </svg>
      </label>
    </>
  );
};

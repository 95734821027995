import React from "react";
import { UserCardStyled } from "./UserCard.styled";
import sprites from "./../../assets/sprites.svg";

interface IUserCardProps {
  photo: string;
  name: string;
  fullName?: string;
}

export const UserCard: React.FC<IUserCardProps> = ({ photo, name, fullName }) => {
  return (
    <>
      <div className="masters-card__avatar">
        <picture>
          {photo ? (
            <img
              src={photo ?? ""}
              alt=""
              width="60"
              height="60"
              loading="lazy"
            />
          ) : (
            <svg width="24" height="24">
              <use href={sprites + "#avatar"}></use>
            </svg>
          )}
        </picture>
      </div>
      <h3 className="masters-card__name">{name ?? ""}</h3>
    </>
  );
};

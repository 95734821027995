import { gql } from "@apollo/client";
import { client } from "../../..";

export const getMasters = async (lng: string, salon: string, salon_slug: string) => {
  return await client
    .query({
      query: gql`
        query getMastersList {
            mastersList(lang: "${lng ?? "pl"}", salon: "${salon_slug ? "" : salon}", salon_slug: "${salon_slug}") {
                avatar
                avatarAlt
                avatarWebp
                id
                name
                title
            }
        }
      `,
    })
};

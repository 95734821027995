import { createSlice } from "@reduxjs/toolkit";
import { IFormState } from "./types";

interface IReviewFormStatus extends IFormState {
  status: string;
}

const initialState: IReviewFormStatus = {
  status: "",
  name: "",
  message: "",
  rating: "",
  master: "",
  masterName: "",
  masterFullName: "",
  allMasters: [],
};

export const reviewFormSlice = createSlice({
  name: "reviewFormStore",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setFields: (state, action) => {
      state.message = action.payload.message
      state.name = action.payload.name
      state.rating = action.payload.rating
      state.master = action.payload.master
      state.masterName = action.payload.masterName
      state.masterFullName = action.payload.masterFullName
    },
    setMasters: (state, action) => {
      state.allMasters = action.payload
    }
  },
});

export const { setStatus, setFields, setMasters } = reviewFormSlice.actions;
export default reviewFormSlice.reducer

import React, { useEffect, useState } from "react";
import { HeaderStyled } from "./Header.styled";
import { LanguageSwitcher } from "../../shared/languageSwitcher/LanguageSwitcher";
import { NavLink } from "react-router-dom";
import { getLogo } from "./api/GetLogo";

interface IHeaderProps {}

export const Header: React.FC<IHeaderProps> = () => {
  const [logo, setLogo] = useState<string>('')

  useEffect(() => {
    getLogo().then((res) => {
      setLogo(res.data.logo)
    })
  }, [])

  return (
    <HeaderStyled className="header">
      <div className="header__logo">
        <NavLink to={"/"} className="header__logo_link">
          {logo && <img
            src={logo}
            alt="Logo"
            width="88"
            height="32"
            loading="lazy"
            className="header__logo_img"
          />}
        </NavLink>
      </div>

      <LanguageSwitcher />
    </HeaderStyled>
  );
};

import { useState } from "react";
import { useCookies } from "react-cookie";
import { IFormState } from "../types";
import { useDispatch } from "react-redux";
import { setFields, setStatus } from "../store";
import { gql, useMutation } from "@apollo/client";
import { client } from "../../../..";
import { useParams } from "react-router-dom";

export const useReviewForm = () => {
  const [formState, setFormState] = useState<IFormState>({
    name: "",
    message: "",
    rating: "",
    master: "",
    masterName: "",
    allMasters: [],
    masterFullName: ""
  });
  const [cookies] = useCookies(["is_member"]);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { salon } = useParams();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
    master?: { name: string; value: string }
  ) => {
    let name = "";
    let value = "";

    name = e?.target.name ?? master?.name ?? "";
    value = e?.target.value ?? master?.value ?? "";

    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await client.mutate({
        mutation: gql`
          mutation MasterComment {
            masterComment(
              input: {
                id: "${formState.master}"
                is_member: ${!!cookies?.is_member}
                message: "${formState.message}"
                name: "${formState.name}"
                rating: "${formState.rating}"
              }
            ) {
              success
            }
          }
        `,
      });

      const wz0 = (time: string | number) => {
        return +time < 10 ? "0" + time : time;
      };

      if (Number(formState.rating) <= 3) {
        const telegram_chat_id = -1002219290125;
        const telegram_bot_token =
          "7121175811:AAFRh0KCNDk039napxsbM9O0yOppSi77wQ0";

        const date = `${wz0(new Date().getDate())}.${wz0(
          new Date().getMonth()+1
        )}.${new Date().getFullYear()}`;
        const time = `${wz0(new Date().getHours())}:${wz0(
          new Date().getMinutes()
        )}`;
        const master = formState.masterFullName;
        const name = formState?.name ? formState?.name : "No name";
        const message = formState?.message ? formState?.message : "No message";
        const link =
          '<a href="https://review.babalab.pl/admin-panel/wp-admin/edit-comments.php">Go to all reviews</a>';

        const text = `
          ❗️You received a low rating❗️\n\n<b>Date:</b> <i>${date}</i>\n<b>Time:</b> <i>${time}</i>\n<b>Rating:</b> <i>${formState.rating}</i>\n<b>Name:</b> <i>${name}</i>\n<b>Message:</b> <i>${message}</i>\n<b>Salon:</b> <i>${salon}</i>\n<b>Master Name:</b> <i>${master}</i>\n\n${link}
        `;

        fetch(`https://api.telegram.org/bot${telegram_bot_token}/sendMessage`, {
          method: "POST",
          body: JSON.stringify({
            chat_id: telegram_chat_id,
            text,
            parse_mode: "HTML",
          }),
          headers: {
            "content-type": "application/json",
          },
        })
          .then((response) => {
            if (response.status !== 200) return Promise.reject();
            return response.json();
          })
          .catch((error) => console.log(error));
      }

      dispatch(setStatus("success"));
      dispatch(setFields(formState));

      setIsLoading(false);
    } catch (err) {
      dispatch(setStatus("error"));
      setIsLoading(false);
      console.log(err);
    }
  };

  return { formState, handleChange, handleSubmit, isLoading };
};

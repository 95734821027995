import { styled } from "styled-components";
import { mediaQuery } from "../../assets/Mixins";

export const ReviewWidgetStyled = styled.div`
  display: grid;

  max-width: 31.25rem;
  margin: 0 auto;

  .rating {
    padding: 3.4375rem 0;

    ${mediaQuery._768(`
        padding: 0;
    `)}

    // .rating__block

    &__block {
      grid-area: 1/1;

      display: none;

      background: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(1.25rem);
      -webkit-backdrop-filter: blur(1.25rem);
      border-radius: 1.5rem;
      text-align: center;

      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;

      padding: 2rem;

      &.is-visible {
        opacity: 1;
        visibility: visible;
      }

      &.is-active {
        display: block;
      }
    }

    // .rating__title

    &__title {
      font-weight: 300;
      font-size: 2rem;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: var(--white);
      -webkit-text-stroke-width: 0.05rem;

      ${mediaQuery._992(`
        font-size: 2.5rem;
        `)}

      i {
        font-family: var(--title-font);
        font-weight: 0;
        -webkit-text-stroke-width: 0;
      }
    }

    // .rating__field

    &__field {
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;

      margin: -0.3125rem;
      padding-top: 1.5rem;

      ${mediaQuery._550(`
        padding-top: 2rem;
        `)}

      input {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;

        white-space: nowrap;
        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        overflow: hidden;
      }

      input:checked + label {
        color: var(--white);
      }

      input:checked + label ~ label {
        color: var(--white);
      }

      label {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.2);

        transition: color 0.3s;

        padding: 0.3125rem;

        svg {
          width: 2.375rem;
          height: 2.375rem;
        }

        &:hover {
          color: var(--white);
        }
      }

      label:hover ~ label {
        color: var(--white);
      }
    }

    // .rating__masters

    &__masters {
      margin-top: 1.5rem;

      ${mediaQuery._550(`
        margin-top: 2rem;
        `)}
    }

    &__masters .swiper-wrapper {
      align-items: center;
    }

    // .rating__form

    &__form {
      display: flex;
      flex-direction: column;
    }

    // .rating__form-label

    &__form-label {
      position: relative;

      &:not(.visually-hidden) {
        margin: 1.5rem 0 0;
      }

      &[data-value] {
        min-height: 2rem;
      }

      &[data-value]::before {
        content: attr(data-value) " ";
        visibility: hidden;
        white-space: pre-wrap;
        width: 100%;
        grid-area: 1/1;

        color: transparent;
      }

      ${mediaQuery._992(`
      &:not(.visually-hidden) {
          margin: 1.5rem 1.375rem 0;
        }
        `)}
    }

    // .rating__form-input

    &__form-input {
      background: transparent;
      font-size: 1rem;
      line-height: 150%;
      leading-trim: both;
      text-edge: cap;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      color: rgba(255, 255, 255, 0.5);

      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      width: 100%;
      padding: 0.35rem 0;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    // .rating__form-textarea

    &__form-textarea {
      position: absolute;
      left: 0;
      top: 0;

      background: transparent;
      font-size: 1rem;
      line-height: 150%;
      leading-trim: both;
      text-edge: cap;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      color: rgba(255, 255, 255, 0.5);

      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      width: 100%;
      height: 100%;

      resize: none;
      overflow: hidden;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    // .rating__text

    &__text {
      padding-top: 1rem;

      line-height: 150%;
      leading-trim: both;
      text-edge: cap;
      text-align: center;
      letter-spacing: -0.03em;
      color: var(--white);
    }

    // .rating__button

    &__button {
      margin-top: 2rem;
      min-width: 100%;
    }

    // .rating__link

    &__link {
    }
  }

  .masters {
    position: relative;

    ${mediaQuery._768(`
    width: 100%;
      padding: 0 1.375rem;
      margin: 0;
        `)}

    &__inner {
      width: calc(100% + 4rem);
      max-width: min(100vw - 3rem, 31.25rem);
      margin: 0 -2rem;

      ${mediaQuery._768(`
      margin: 2rem 0 0;
        width: auto;
        max-width: calc(min((100vw - 3rem), 31.25rem) - 2rem * 2 - 1.5rem * 2);
        `)}
    }

    // .masters__current

    &__current {
    }

    // .masters__card

    &__card {
      user-select: none;

      ${mediaQuery._768(`
      max-width: 33.333%;
        `)}
    }

    // .masters__arrow

    &__arrow {
      position: absolute;

      z-index: 10;
      place-items: center;

      color: var(--white);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);

      height: 1.75rem;
      width: 1.75rem;

      &.hidden {
        /* opacity: 0; */
        height: 115px;
        width: 115px;
        top: 0;
        left: 20px;
        opacity: 0;

        &.is-next {
          right: 20px;
          left: auto;
        }
      }

      @media screen and (max-width: 768px) {
        opacity: 0;
        width: 6rem;
        height: 100%;
      }

      ${mediaQuery._768(`
      display: grid;
        `)}

      @media (any-hover: hover) {
        transition: background 0.2s, color 0.2s;

        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }

        &:active {
          color: rgba(255, 255, 255, 0.5);
          background: rgba(255, 255, 255, 0.1);
        }
      }

      svg {
        width: 55%;
        height: 55%;
      }

      &:nth-child(2) {
        left: 0;
        top: 50%;
        transform: translate(-60%, -50%);
      }

      &:nth-child(3) {
        right: 0;
        top: 50%;
        transform: translate(60%, -50%);
      }
    }
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 300ms;
  }
`;

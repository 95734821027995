import { gql } from "@apollo/client";
import { client } from "../../..";

export const getBackground = async (salon?: string) => {
  return await client.query({
    query: gql`
      query SalonBackground {
        getSalonBackground(salon_slug: "${salon ?? ""}")
      }
    `,
  });
};

// mixins.js

// Миксины для медиа-запросов
export const mediaQuery = {
  _992: (...styles: string[]) => `
      @media (min-width: 992px) {
        ${styles}
      }
    `,
  _768: (...styles: string[]) => `
      @media (min-width: 768px) {
        ${styles}
      }
    `,
  _550: (...styles: string[]) => `
      @media (min-width: 550px) {
        ${styles}
      }
    `,
  _426: (...styles: string[]) => `
      @media (min-width: 426px) {
        ${styles}
      }
    `,
};

// Миксин для включения шрифтов
export const fontInclude = (
  name: string,
  weight: string,
  display: string,
  style: string,
  src: string
) => `
    font-weight: ${weight};
    font-family: ${name};
    font-display: ${display};
    font-style: ${style};
    src: url('${src}.woff2') format('woff2');
  `;

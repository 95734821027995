import React, { useEffect, useState } from "react";
import { ReviewFormStyled } from "./ReviewForm.styled";
import { RatingItem } from "../../../shared/ui/ratingItem/RatingItem";
import { UserCard } from "../../../entities/userCard/UserCard";
import { Input } from "../../../shared/ui/input/Input";
import { useReviewForm } from "../model/hooks/useReviewForm";
import { Button } from "../../../shared/ui/button/Button";

import { ReviewFormSlider } from "./ReviewFormSlider";
import { ReviewFormSliderMob } from "./ReviewFormSliderMob";
import { getTitle } from "../api/GetTitle";
import HTMLReactParser from "html-react-parser";
import { getNamePh } from "../api/GetNamePh";
import { getMessagePh } from "../api/GetMessagePh";
import { getButtonText } from "../api/GetButtonText";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBackground } from "../api/GetBackground";
import { useGetTexts } from "../model/hooks/useGetTexts";

interface IReviewFormProps {}

export const ReviewForm: React.FC<IReviewFormProps> = () => {
  const { handleChange, handleSubmit, formState, isLoading } = useReviewForm();
  
  const {title, inputNamePh, inputMessagePh, buttonTitle} = useGetTexts()

  return (
    <ReviewFormStyled
      onSubmit={handleSubmit}
      className="rating__block is-active is-visible"
    >
      <h1 className="rating__title">{HTMLReactParser(title)}</h1>
          <div className="rating__field">
            <RatingItem
              id="rating-5"
              value="5"
              name="rating"
              onChange={handleChange}
            />
            <RatingItem
              id="rating-4"
              value="4"
              name="rating"
              onChange={handleChange}
            />
            <RatingItem
              id="rating-3"
              value="3"
              name="rating"
              onChange={handleChange}
            />
            <RatingItem
              id="rating-2"
              value="2"
              name="rating"
              onChange={handleChange}
            />
            <RatingItem
              id="rating-1"
              value="1"
              name="rating"
              onChange={handleChange}
            />
          </div>

          <ReviewFormSlider handleChange={handleChange} />
          <ReviewFormSliderMob handleChange={handleChange} />

          <div className="rating__form">
            <Input
              type="text"
              name="name"
              placeholder={inputNamePh}
              onChange={handleChange}
              value={formState.name}
            />
            <Input
              type="text"
              name="message"
              placeholder={inputMessagePh}
              onChange={handleChange}
              value={formState.message}
            />

            <Button isLoading={isLoading}>{buttonTitle}</Button>
          </div>
    </ReviewFormStyled>
  );
};

import React, { useEffect, useState } from "react";
import { MainLayout } from "../../shared/layouts/MainLayout";
import { Header } from "../../widgets/header/Header";
import { ReviewWidget } from "../../widgets/review/ReviewWidget";
import { ReviewPageStyled } from "./ReviewPage.styled";
import { Preloader } from "../../shared/ui/loader/Loader";
import { useSelector } from "react-redux";

interface IReviewPageProps {}

export const ReviewPage: React.FC<IReviewPageProps> = () => {
  const masters = useSelector((state: any) => state.reviewForm.allMasters);

  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    if (!masters?.length) return;

    setTimeout(() => setIsHide(true), 1000);
  }, [masters]);

  return (
    <MainLayout>
      <main className="main" style={{ minHeight: "calc(100vh - 100px)" }}>
        <Preloader className={"preloader " + (isHide ? "hidden" : "")} />

        <ReviewPageStyled className="rating">
          <ReviewWidget />
        </ReviewPageStyled>
      </main>
    </MainLayout>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import reviewFormReducer from "../features/reviewForm/model/store";
import langReducer from "../shared/languageSwitcher/store";

const store = configureStore({
  reducer: {
    reviewForm: reviewFormReducer,
    lang: langReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { styled } from "styled-components";

export const LanguageSwitcherStyled = styled.nav`
  position: relative;

  .header {
    // .header__lang-target

    &__lang-target {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      text-transform: capitalize;

      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(0.5rem);
      -webkit-backdrop-filter: blur(0.5rem);
      border-radius: 3.125rem;

      font-family: var(--font-2);
      font-weight: 500;
      font-size: 1rem;
      line-height: 150%;
      leading-trim: both;
      text-edge: cap;
      letter-spacing: -0.03em;
      color: var(--white);

      padding: 0.5rem 0.875rem;

      transition: background 0.2s, color 0.2s;

      @media (any-hover: hover) {
        &:hover {
          background: var(--white);
          color: var(--accent);
        }

        &:active {
          background: #ececec;
          color: var(--accent);
        }
      }

      svg {
        width: 0.75rem;
        height: 0.5rem;

        transition: transform 0.2s;
      }
    }

    // .header__lang-list

    &__lang-list {
      position: absolute;
      left: 0;
      top: 100%;
      transform: translate(0, 0.3125rem);

      background: var(--white);
      border-radius: 0.65rem;

      width: 100%;

      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;

      button,
      a {
        display: block;

        color: var(--accent);
        text-align: center;
        border-radius: 0.4375rem;

        width: calc(100% - 0.5rem);
        padding: 0.0313rem 0.625rem;
        margin: 0.25rem;

        font-size: inherit;
        background: transparent;

        @media (any-hover: hover) {
          transition: color 0.15s, background 0.15s;

          &:hover {
            color: var(--white);
            background: var(--accent);
          }

          &:active {
            color: var(--accent-active);
          }
        }
      }
    }

    // .header__lang-item

    &__lang-item {
    }

    // .header__lang-link

    &__lang-link {
    }
  }

  &.is-active .header__lang-target {
    background: var(--white);
    color: var(--accent);
  }

  &.is-active .header__lang-target svg {
    transform: scaleY(-1);
  }

  &.is-active .header__lang-list {
    opacity: 1;
    visibility: visible;
  }
`;

import { styled } from "styled-components";
import { mediaQuery } from "../../assets/Mixins";
const container = 1440;
const containerPadding = "var(--container-padding)"; // Используйте переменную CSS для отступов

export const MainLayoutsStyled = styled.div`
  height: 100vh;

  .wrapper {
    position: relative;

    display: flex;
    flex-direction: column;

    flex: 1 1 auto;

    padding: 0.5rem;
    ${mediaQuery._992(`
      padding: 3.125rem;
	  `)}
  }

  .wrapper-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    border-radius: 1rem;
    background: center / cover no-repeat;

    width: calc(100% - 0.5rem * 2);
    height: calc(100% - 0.5rem * 2);

    ${mediaQuery._992(`
      width: calc(100% - 3.125rem * 2);
      height: calc(100% - 3.125rem * 2);
	  `)}
  }
`;

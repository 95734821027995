import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTitle } from "../../api/GetTitle";
import { getNamePh } from "../../api/GetNamePh";
import { getMessagePh } from "../../api/GetMessagePh";
import { getBackground } from "../../api/GetBackground";
import { getButtonText } from "../../api/GetButtonText";

export const useGetTexts = () => {
  const path = window.location.pathname.split("/");

  const [title, setTitle] = useState("");
  const [inputNamePh, setInputNamePh] = useState("");
  const [inputMessagePh, setInputMessagePh] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);

  const lang = path[1] ? path[1] : "pl";

  const { lng, salon } = useParams();

  useEffect(() => {
    getTitle(lang.toLowerCase()).then((res) => {
      setTitle(res.data.raitingFormTitle);
    });

    getNamePh(lang.toLowerCase()).then((res) => {
      setInputNamePh(res.data.ratingInputNamePlaceholder);
    });

    getMessagePh(lang.toLowerCase()).then((res) => {
      setInputMessagePh(res.data.ratingInputTextareaPlaceholder);
    });

    getButtonText(lang.toLowerCase()).then((res) => {
      setButtonTitle(res.data.ratingSubmitButtonText);
      setIsPageLoading(false);
    });
  }, [lang]);

  return { title, inputNamePh, inputMessagePh, buttonTitle, isPageLoading };
};

import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./app/routes/index";
import { Provider } from "react-redux";
import store from "./store";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";
import { Preloader } from "./shared/ui/loader/Loader";

const root = ReactDOM.createRoot(
  document.getElementById("project-root") as HTMLElement
);

const path = window.location.pathname.split('/');
if(path[1]) {
  document.documentElement.setAttribute("lang", path[1]);
}

export const client = new ApolloClient({
  uri: "https://review.babalab.pl/admin-panel/graphql/",
  cache: new InMemoryCache(),
});


root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </ApolloProvider>
);

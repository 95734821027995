import { gql } from "@apollo/client";
import { client } from "../../..";

export const getThanksGoogleTitle = async (lng: string) => {
  return await client.query({
    query: gql`
      query getThanksExtendGoogleButtonText {
        thanksExtendGoogleButtonText(lang: "${lng ?? "pl"}")
      }
    `,
  });
};

import { createBrowserRouter } from "react-router-dom";
import { ReviewPage } from "../../pages/reviewPage/ReviewPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ReviewPage />,
  },
  {
    path: "/:salon",
    element: <ReviewPage />,
  },
  {
    path: "/:lng/:salon",
    element: <ReviewPage />,
  },
]);

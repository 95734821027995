import { styled } from "styled-components";
import { mediaQuery } from "../../assets/Mixins";

export const HeaderStyled = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  //transform: translate(0, var(--pos));

  //transition: transform .2s ease;

  z-index: 3;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  padding: 1.5rem 1.5rem 0;

  perspective: 400px;

  ${mediaQuery._992(`
      padding: 5.125rem 5.125rem 0;
  `)}

  // .header__logo

  .header {
    &__logo {
      position: relative;
      z-index: 4;

      // .header__logo_link

      &_link {
        display: block;
        max-width: 9.375rem;
      }

      // .header__logo_img

      &_img {
        width: auto;
        min-height: 100%;
        object-fit: contain;
      }
    }

  }
`;

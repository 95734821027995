import { gql } from "@apollo/client";
import { client } from "../../..";

export const getThanksTitle = async (lng: string) => {
  return await client.query({
    query: gql`
      query getThanksExtendTitle {
        thanksExtendTitle(lang: "${lng ?? "pl"}")
      }
    `,
  });
};

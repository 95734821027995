import { styled } from "styled-components";

export const ReviewThanksStyled = styled.section`
  .link {
    display: inline-block;

    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    leading-trim: both;
    text-edge: cap;
    letter-spacing: -0.03em;
    color: var(--white);

    text-decoration: underline;
    text-underline-offset: 0.35rem;

    margin-top: 1rem;

    &:hover {
      text-decoration: underline;
    }

    @media (any-hover: hover) {
      transition: color 0.15s;

      &:hover {
        color: var(--accent);
      }

      &:active {
        color: var(--accent-active);
      }
    }
  }
`;

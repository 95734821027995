import { styled } from "styled-components";

export const ButtonStyled = styled.button`
  // .rating__form-submit

  margin-top: 2rem;
  position: relative;

  background: var(--accent);
  border-radius: 2.75rem;
  color: var(--white);
  font-weight: 500;
  line-height: 150%;
  leading-trim: both;
  text-edge: cap;
  letter-spacing: -0.03em;

  min-width: 100%;

  @keyframes loading {
    to {
      rotate: 360deg;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  cursor: pointer;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 0.75rem 1.125rem;

  background: var(--accent);
  color: var(--white);
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  leading-trim: both;
  text-edge: cap;
  letter-spacing: -0.03em;
  border-radius: 2.8125rem;

  span {
    position: relative;
    grid-area: 1/1;

    display: grid;
    place-items: center;

    transition: opacity 0.2s;

    &:nth-child(2) {
      opacity: 0;
    }
  }

  @media (any-hover: hover) {
    transition: background 0.3s;

    &:hover {
      background: var(--accent-hover);
    }

    &:active {
      background: var(--accent-active);
    }
  }

  .is-loading .button span {
    &:nth-child(1) {
      opacity: 0;
    }

    &:nth-child(2) {
      opacity: 1;
    }
  }
`;

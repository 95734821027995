import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { UserCard } from "../../../entities/userCard/UserCard";
import sprites from "./../../../assets/sprites.svg";

import { Navigation, EffectCoverflow } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import { getMasters } from "../api/GetMasters";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Preloader } from "../../../shared/ui/loader/Loader";
import { setMasters } from "../model/store";

interface IReviewFormSliderProps {
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    master: { name: string; value: string }
  ) => void;
}

export const ReviewFormSlider: React.FC<IReviewFormSliderProps> = ({
  handleChange,
}) => {
  const navigationPrevRef: any = React.useRef(null);
  const navigationNextRef: any = React.useRef(null);
  const [slides, setSlides] = useState([]);
  const [swiperGlobal, setSwiperGlobal] = useState<any>({});
  const lang = useSelector((state: any) => state.lang.lang);

  const params = useParams();
  const dispatch = useDispatch();

  const handleSlideChange = (swiper: any) => {
    const activeIndex = swiper.realIndex;
    let activeSlideElement: any = {};

    for (let item of swiper.slides) {
      if (+item.getAttribute("data-swiper-slide-index") === +activeIndex)
        activeSlideElement = item;
    }

    if (
      !Object.keys(activeSlideElement).length ||
      !activeSlideElement?.getAttribute("data-slug")
    )
      return;

    const activeSlug = activeSlideElement?.getAttribute("data-slug");
    const activeFullName = activeSlideElement?.getAttribute("data-full-name");
    const activeName = activeSlideElement?.querySelector(
      ".masters-card__name"
    )?.textContent;

    // if (activeSlug)
    handleChange(undefined, {
      name: "master",
      value: activeSlug,
    });
    handleChange(undefined, {
      name: "masterName",
      value: activeName,
    });
    handleChange(undefined, {
      name: "masterFullName",
      value: activeFullName,
    });
  };

  useEffect(() => {
    setSlides([]);
    getMasters(
      params?.lng ?? lang.toLowerCase(),
      params?.salon ?? "35",
      params?.salon ?? "krakow"
    ).then((res) => {
      setSlides(res.data.mastersList);
      dispatch(setMasters(res.data.mastersList));
    });
  }, [lang]);

  return (
    <>
      <div className="rating__masters masters visible-on-desktop">
        {!!slides?.length && (
          <Swiper
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            modules={[Navigation, EffectCoverflow]}
            className="masters__inner"
            loop={slides?.length > 3}
            spaceBetween={0}
            effect="coverflow"
            initialSlide={4 - slides?.length}
            centeredSlides={true}
            slideToClickedSlide={true}
            slidesPerView="auto"
            coverflowEffect={{
              rotate: 0,
              stretch: -24,
              depth: 150,
              modifier: 1,
              slideShadows: false,
            }}
            onSlideChange={handleSlideChange}
            onInit={(swiper: any) => {
              setSwiperGlobal(swiper);
              swiper.slides.forEach((slide: any) => {
                slide.onclick = () => {
                  swiper.slideToLoop(slide.dataset.swiperSlideIndex);
                };
              });
            }}
          >
            {slides?.map((item: any, index: number) => (
              <SwiperSlide
                key={index}
                data-slug={item?.id ?? "0"}
                data-full-name={item.title}
                className="masters__card masters-card"
              >
                <UserCard name={item?.name} photo={item?.avatar} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {slides?.length > 1 && (
          <>
            <button
              ref={navigationPrevRef}
              className="masters__arrow is-prev"
              type="button"
            >
              <svg width="16" height="16">
                <use href={sprites + "?v=1#arrow-prev"}></use>
              </svg>
            </button>
            <button
              ref={navigationNextRef}
              className="masters__arrow is-next"
              type="button"
            >
              <svg width="16" height="16">
                <use href={sprites + "?v=1#arrow-next"}></use>
              </svg>
            </button>
          </>
        )}
        {slides?.length > 1 && (
          <>
            <button
              onClick={(_) => swiperGlobal.slidePrev()}
              className="masters__arrow is-prev hidden"
              type="button"
            />
            <button
              onClick={(_) => swiperGlobal.slideNext()}
              className="masters__arrow is-next hidden"
              type="button"
            />
          </>
        )}
      </div>
    </>
  );
};

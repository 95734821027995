import { gql } from "@apollo/client";
import { client } from "../../..";

export const getMessagePh = async (lng: string) => {
  return await client.query({
    query: gql`
      query getRatingInputTextareaPlaceholder {
        ratingInputTextareaPlaceholder(lang: "${lng}")
      }
    `,
  });
};

import { styled } from "styled-components";

export const ReviewFormStyled = styled.form`

  .masters-card {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.5rem;

    height: auto;

    padding: 0.5rem;

    cursor: pointer;
    
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;

      border: 1px solid transparent;
      border-radius: 0.5rem;

      width: 100%;
      height: 100%;

      transition: border-color 0.2s;
    }

    &.swiper-slide-active::before {
      border-color: var(--white);
    }

    // .masters-card__avatar

    &__avatar {
      position: relative;

      display: inline-block;

      border-radius: 50%;
      object-fit: cover;
      background: rgba(255, 255, 255, 0.1);
      color: var(--white);

      width: 4.5625rem;
      height: 4.5625rem;

      svg {
        width: 100%;
        height: 100%;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;

        border-radius: 50%;
        object-fit: cover;

        width: 100%;
        height: 100%;
      }
    }

    // .masters-card__name

    &__name {
      font-size: 1rem;
      line-height: 150%;
      leading-trim: both;
      text-edge: cap;
      text-align: center;
      letter-spacing: -0.03em;
      color: var(--white);

      margin-top: 0.35rem;

      @media(min-width: 550px) {
        margin-top: 0.6rem;
      }
    }
  }
  
`;

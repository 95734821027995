import React, { ReactElement, useEffect, useState } from "react";
import mainBgd from "./assets/img/main-background.jpg";
import { MainLayoutsStyled } from "./MainLayouts.styled";
import { GlobalStyles } from "../../assets/GlobalStyles.styled";
import { Header } from "../../widgets/header/Header";
import { getBackground } from "../../features/reviewForm/api/GetBackground";
import { useParams } from "react-router-dom";

interface IMainLayoutProps {
  children: ReactElement;
}

export const MainLayout: React.FC<IMainLayoutProps> = ({ children }) => {

  const [background, setBackground] = useState('')
  
  const { salon } = useParams();

  useEffect(() => {
    getBackground(salon?.toLowerCase()).then((res) => {
      setBackground(res.data.getSalonBackground);
    });
  }, [])

  return (
    <MainLayoutsStyled>
      <GlobalStyles />
      <div className="wrapper">
        <Header />
        <div
          className="wrapper-background"
          aria-hidden="true"
          style={{ backgroundImage: `url(${!!background ? background : mainBgd})` }}
        ></div>
        {children}
      </div>
    </MainLayoutsStyled>
  );
};

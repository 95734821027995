import { styled } from "styled-components";
import { mediaQuery } from "../../assets/Mixins";

export const ReviewPageStyled = styled.section`
  padding: 3.4375rem 0;

  ${mediaQuery._768(`
    padding: 0;
  `)}
`;

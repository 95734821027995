import { gql } from "@apollo/client";
import { client } from "../..";

export const getLanguageList = async (lng: string) => {
  return await client.query({
    query: gql`
      query getLanguageList {
        languageList(lang: "${lng ? lng : 'pl'}") {
            title
            url
        }
    }
    `,
  });
};

import { gql } from "@apollo/client";
import { client } from "../../..";

export const getTitle = async (lng: string) => {
  return await client
    .query({
      query: gql`
        query getRaitingFormTitle {
            raitingFormTitle(lang: "${lng ?? 'pl'}")
        }
      `,
    })
};

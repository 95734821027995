import React, { useRef, useState } from "react";
import { ReviewWidgetStyled } from "./ReviewWidget.styled";
import { UserCard } from "../../entities/userCard/UserCard";
import { RatingItem } from "../../shared/ui/ratingItem/RatingItem";
import { ReviewForm } from "../../features/reviewForm/ui/ReviewForm";
import { ReviewThanks } from "../../features/reviewThanks/ui/ReviewThanks";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";

interface IReviewWidgetProps {}

export const ReviewWidget: React.FC<IReviewWidgetProps> = () => {
  const formStatus = useSelector((state: any) => state.reviewForm.status);
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);

  return (
    <ReviewWidgetStyled className="rating__inner">
      <SwitchTransition>
        <CSSTransition
          key={formStatus}
          nodeRef={nodeRef}
          in={true}
          timeout={300}
          classNames="fade"
          addEndListener={(node, done) => {
            if (node instanceof HTMLElement) {
              node.addEventListener("transitionend", done, false);
            }
          }}
        >
          <div ref={nodeRef}>
            {formStatus === "success" ? <ReviewThanks /> : <ReviewForm />}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </ReviewWidgetStyled>
  );
};

import React, { useEffect, useState } from "react";
import { LanguageSwitcherStyled } from "./LanguageSwitcher.styled";
import sprites from "./../../assets/sprites.svg";
import { getLanguageList } from "../api/GetLanguageList";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLang } from "./store";

interface ILanguageSwitcherProps {}

interface ILangItem {
  title: string;
  url: string;
}

export const LanguageSwitcher: React.FC<ILanguageSwitcherProps> = () => {
  const path = window.location.pathname.split("/");
  const [isActive, setIsActive] = useState(false);
  const [languagesList, setLanguagesList] = useState<ILangItem[]>([]);
  const isDefaultLang = ["en", "ru", "uk"].every((element) =>
    path.includes(element)
  );
  const lang = isDefaultLang ? "pl" : path[1];
  const [currentLang, setCurrentLang] = useState<string>(lang);
  const dispatch = useDispatch();

  useEffect(() => {
    getLanguageList(currentLang)
      .then((res) => {
        setLanguagesList(res.data.languageList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();

  const changeLanguage = (lng: any) => {
    let path = window.location.pathname.split("/");
    path[1] = lng.toLowerCase();

    setIsActive(false)
    navigate(path.join("/"));
    dispatch(setLang(lng));
    setCurrentLang(lng);

    document.documentElement.setAttribute("lang", lng.toLowerCase());

    getLanguageList(lng.toLowerCase())
      .then((res) => {
        setLanguagesList(res.data.languageList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <LanguageSwitcherStyled
      className={"header__lang " + (isActive ? "is-active" : "")}
    >
      <button
        className="header__lang-target"
        type="button"
        aria-label={currentLang}
        onClick={(_) => setIsActive((prev) => !prev)}
      >
        <span>{currentLang}</span>
        <svg width="14" height="8">
          {/* <use href="/assets/img/sprites.svg#drop-down-arrow"></use> */}
          <use href={sprites + "#drop-down-arrow"} />
        </svg>
      </button>
      <ul className="header__lang-list">
        {languagesList.map((lang) => (
          <li key={lang.title} className="header__lang-item">
            <button
              onClick={(_) => changeLanguage(lang.title)}
              className="header__lang-link"
            >
              {lang.title}
            </button>
          </li>
        ))}
      </ul>
    </LanguageSwitcherStyled>
  );
};

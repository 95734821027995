import React, { useEffect, useState } from "react";
import { Button } from "../../../shared/ui/button/Button";
import { ReviewThanksStyled } from "./ReviewThanks.styled";
import { useSelector } from "react-redux";
import { getThanksGoogleTitle } from "../api/GetThanksGoogleTitle";
import { getThanksTitle } from "../api/GetThanksTitle";
import { getThanksText } from "../api/GetThanksText";
import HTMLReactParser from "html-react-parser";
import { getThanksLink } from "../api/GetThanksLink";
import { getThanksGoogleUrl } from "../api/GetThanksGoogleUrl";
import { useParams } from "react-router-dom";

interface IReviewThanksProps {}

export const ReviewThanks: React.FC<IReviewThanksProps> = () => {
  const rating = useSelector((state: any) => state.reviewForm.rating);
  const fields = useSelector((state: any) => state.reviewForm);
  const lang = useSelector((state: any) => state.lang.lang).toLowerCase();
  const params = useParams();

  const { lng } = useParams();

  const [title, setTitle] = useState("");
  console.log(fields);
  
  const [text, setText] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [link, setLink] = useState("");
  const [googleTitle, setGoogleTitle] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const isGoodReview = rating === "5" && fields.message

  useEffect(() => {
    getThanksGoogleTitle(lng ?? lang).then((res) => {
      setGoogleTitle(res.data.thanksExtendGoogleButtonText);
    });

    getThanksGoogleUrl("", params?.salon ?? "").then((res) => {
      setGoogleLink(res.data.thanksExtendGoogleFeedbackURL);
    });

    getThanksTitle(lng ?? lang).then((res) => {
      setTitle(res.data.thanksExtendTitle);
    });

    getThanksText(isGoodReview ? 'thanksExtendText' :'thanksText', lng ?? lang).then((res) => {
      setText(res?.data?.thanksExtendText ?? res?.data?.thanksText ?? "");
    });

    getThanksLink(lng ?? lang).then((res) => {
      if (!res.data?.thanksExtendLink) return;

      setLinkTitle(res.data?.thanksExtendLink[0]?.title);
      setLink(res.data?.thanksExtendLink[0]?.url);

      setIsLoading(false);
    });
  }, [lang]);

  return (
    <ReviewThanksStyled className="rating__block is-active is-visible">
      {/* {isLoading && <div className="loader"></div>} */}

      <h2 className="rating__title">
        <i>{HTMLReactParser(title ?? "")}</i>
      </h2>
      <div className="rating__text">{HTMLReactParser(text ?? "")}</div>
      {isGoodReview && <Button href={googleLink}>{googleTitle}</Button>}
      {isGoodReview && <br />}
      {linkTitle && <a target="_blank" href={link} className="rating__link link">
        {linkTitle ?? "Visit"}
      </a>}
    </ReviewThanksStyled>
  );
};

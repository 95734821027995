import { gql } from "@apollo/client";
import { client } from "../../..";

export const getThanksText = async (queryText: string, lng: string) => {
  return await client.query({
    query: gql`
      query getThanksExtendText {
        ${queryText}(lang: "${lng ?? "pl"}")
      }
    `,
  });
};

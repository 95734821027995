import React, { ReactElement } from "react";
import { ButtonStyled } from "./Button.styled";

interface IButtonProps {
  onClick?: () => void;
  children: string;
  href?: string;
  isLoading?: boolean
}

export const Button: React.FC<IButtonProps> = ({ onClick, children, href, isLoading }) => {
  return (
    <ButtonStyled
      className="rating__form-submit button"
      aria-label={children}
      onClick={onClick}
    >
      {isLoading && <span>
        <div className="loader"></div>
      </span>}
      <span>{children}</span>
      {href && <a href={href} target="_blank"></a>}
    </ButtonStyled>
  );
};

import { gql } from "@apollo/client";
import { client } from "../../..";

export const getNamePh = async (lng: string) => {
  return await client.query({
    query: gql`
      query getRatingInputNamePlaceholder {
        ratingInputNamePlaceholder(lang: "${lng}")
      }
    `,
  });
};

import React from "react";

interface IInputProps {
  name: string
  placeholder: string
  type: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

export const Input: React.FC<IInputProps> = ({...props}) => {
  return (
    <label className="rating__form-label">
      <input
        {...props}
        className="rating__form-input"
      />
    </label>
  );
};

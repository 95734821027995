import { gql } from "@apollo/client";
import { client } from "../../..";

export const getThanksGoogleUrl = async (salon: string, salon_slug: string) => {
  return await client.query({
    query: gql`
      query getExtendGoogleFeedbackURL {
        thanksExtendGoogleFeedbackURL(salon: "${salon_slug ? "" : salon}", salon_slug: "${salon_slug}")
      }
    `,
  });
};

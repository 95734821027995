import React from "react";
import { Comment } from "react-loader-spinner";

interface IPreloaderProps {
    className: string
}

export const Preloader: React.FC<IPreloaderProps> = (props) => {
  return (
    <div {...props} >
      <Comment
        visible={true}
        height="80"
        width="80"
        ariaLabel="comment-loading"
        wrapperStyle={{}}
        wrapperClass="comment-wrapper"
        color="#fff"
        backgroundColor="#0158C3"
      />
    </div>
  );
};

import { gql } from "@apollo/client";
import { client } from "../../..";

export const getThanksLink= async (lng: string) => {
  return await client.query({
    query: gql`
      query getThanksExtendLink {
        thanksExtendLink(lang: "${lng ?? "pl"}") {
          target
          title
          url
        }
      }
    `,
  });
};

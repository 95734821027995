import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { UserCard } from "../../../entities/userCard/UserCard";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { getMasters } from "../api/GetMasters";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import sprites from "./../../../assets/sprites.svg";

import { Navigation } from "swiper/modules";

interface IReviewFormSliderProps {
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    master: { name: string; value: string }
  ) => void;
}

export const ReviewFormSliderMob: React.FC<IReviewFormSliderProps> = ({}) => {
  const navigationPrevRef: any = React.useRef(null);
  const navigationNextRef: any = React.useRef(null);

  const [slides, setSlides] = useState([]);
  const lang = useSelector((state: any) => state.lang.lang);

  const params = useParams();

  useEffect(() => {
    setSlides([]);
    getMasters(
      params?.lng ?? lang.toLowerCase(),
      params?.salon ?? "35",
      params?.salon ?? "krakow"
    ).then((res) => {
      setSlides(res.data.mastersList);
    });
  }, [lang]);

  let counter = 0;

  return (
    <div className="rating__masters masters visible-on-mob">
      {!!slides?.length && (
        <Swiper
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          modules={[Navigation]}
          className="masters__inner"
          loop={slides?.length > 3}
          spaceBetween={16}
          initialSlide={4 - slides?.length}
          centeredSlides={true}
          slidesPerView={2}
          onInit={(swiper: any) => {
            swiper.slides.forEach((slide: any) => {
              slide.onclick = () => {
                swiper.slideToLoop(slide.dataset.swiperSlideIndex);
              };
            });
          }}
        >
          {slides?.map((item: any, index: number) => (
            <SwiperSlide
              key={counter++}
              data-slug={item?.id ?? "0"}
              className="masters__card masters-card"
            >
              <UserCard name={item?.name} photo={item?.avatar} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <button
        ref={navigationPrevRef}
        className="masters__arrow is-prev"
        type="button"
      >
        <svg width="16" height="16">
          <use href={sprites + "?v=1#arrow-prev"}></use>
        </svg>
      </button>
      <button
        ref={navigationNextRef}
        className="masters__arrow is-next"
        type="button"
      >
        <svg width="16" height="16">
          <use href={sprites + "?v=1#arrow-next"}></use>
        </svg>
      </button>
    </div>
  );
};
